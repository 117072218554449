<template>
  <div class="login">
    <div class="title">
      <p>{{ $t("login.title1") }}<br />{{ $t("login.title2") }}</p>
    </div>
    <van-form @submit="onSubmit">
      <van-cell-group>
        <van-field
          v-model="username"
          :border="true"
          name="username"
          :placeholder="$t('login.username')"
          :rules="[{ required: true, message: $t('login.username') }]"
        />
      </van-cell-group>
      <van-cell-group>
        <van-field
          v-model="password"
          type="password"
          name="password"
          :placeholder="$t('login.password')"
          :rules="[{ required: true, message: $t('login.password') }]"
        />
      </van-cell-group>
      <div style="margin-top: 32px;">
        <van-button v-if="loading" round block type="primary" native-type="submit">{{
          $t("login.login")
        }}</van-button>
        <van-button v-else loading round block type="primary" />
      </div>
    </van-form>
    <div class="bottom">
      <div class="btm-box">
        <span class="btm-left" @click="pushClick()">
          {{ $t("login.for_password") }} |
        </span>
        <span class="btm-right" @click="$router.push('/singin')">
          {{ $t("login.create_password") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { encrypt } from "@/network/rsaEncrypt";
import { appLogin } from "@/network/login";
export default {
  name: "login",
  data() {
    return {
      loading:true,
      username: "",
      password: "",
      redirect: null,
    };
  },
  watch: {
    // $route: {
    //   handler: function(route) {
    //     this.redirect = route.query && route.query.redirect;
    //   },
    //   immediate: true,
    // },
  },
  methods: {
    pushClick() {
      // N 表示是通过登录页面跳转的
      this.$router.push({
        path: "/resetVerify",
        query: {
          type: "N",
        },
      });
    },
    onSubmit(values) {
      this.loading = false
      let objData = {};
      objData.password = encrypt(values.password);
      objData.username = values.username;
      appLogin(objData)
        .then((res) => {
          if (res.token) {
            window.localStorage.setItem("token", res.token);
            window.localStorage.setItem("userInfo", JSON.stringify(res.user));
            this.$router.replace("/home");
          }else{
            this.$dialog
            .alert({
              message: res.message,
              confirmButtonText:this.$t('stringCode.Done'),
            })
            .then(() => {
              // on close
            });
          }
        })
        .catch((err) => {
          this.$dialog
            .alert({
              message: err.message,
              confirmButtonText:this.$t('stringCode.Done'),
            })
            .then(() => {
              // on close
            });
        }).finally(()=>{
          this.loading = true
        });
    },
  },
};
</script>

<style scoped>
.login {
  padding: 0 25px;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
}
.title {
  padding-top: 80px;
  margin-bottom: 90px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
}
.login /deep/ .van-cell {
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-sizing: border-box;
  border-radius: 8px;
  margin-bottom: 16px;
}
.login /deep/ .van-field__control::placeholder {
  color: #ccc;
}
.bottom {
  /* position: fixed; */
  margin-top: 150px;
}
.btm-box {
  margin: 0 auto;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #2dd070;
}
</style>
